@layer components {
  .input {
    @apply min-h-[46px] min-w-[110px] appearance-none rounded-[5px] border border-input-border-light bg-input-background-light px-3 py-2 text-input-text-light outline-none placeholder:text-input-placeholder-light focus:border-primary-700 focus:outline-none dark:border-input-border-dark dark:bg-input-background-dark dark:text-input-text-dark placeholder:dark:text-input-placeholder-dark;
  }
  .select {
    @apply min-w-[110px] rounded-[5px] border border-input-border-light bg-input-background-light px-3 py-2 text-input-text-light outline-none placeholder:text-input-placeholder-light focus:border-primary-700 dark:border-input-border-dark dark:bg-input-background-dark dark:text-input-text-dark placeholder:dark:text-input-placeholder-dark;

    appearance: none;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24 "><path stroke="black" fill="black" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem;
  }
  .selectDark {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path  stroke="white" fill="white" stroke-width="2" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem;
  }
}

@layer utilities {
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  input::-ms-reveal {
    display: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px var(--tw-bg-opacity) inset;
    -webkit-text-fill-color: currentColor;
  }

  input[type="number"]::-moz-inner-spin-button {
    display: none;
  }
}
