:root {
  --primary-color: #7561ab;

  --primary-50: #f2f2fb;
  --primary-100: #e7e8f8;
  --primary-200: #d4d4f1;
  --primary-300: #bcbae7;
  --primary-400: #a39ddc;
  --primary-500: #9285cf;
  --primary-600: #806cbf;
  --primary-700: #7561ab; // Color corporativo
  --primary-800: #5b4b88;
  --primary-900: #4b426d;

  --corp: #7561ab;
  --corp-500: #9285cf;

  --background-primary-light: #f8f7fc;
  --background-primary-dark: #101826;
  --background-light: #fafafa;
  --background-light-50: #e1e1e1;
  --background-dark: #09090b;
  --background-dark-50: #0d0d0d;
  --background-dark-100: #1e1e1e;

  --surface-0: #ffffff;
  --surface-50: #f8f7fc;
  --surface-100: #f3f4f6;
  --surface-200: #e5e7eb;
  --surface-300: #d1d5db;
  --surface-400: #9ca3af;
  --surface-500: #6b7280;
  --surface-600: #4b5563;
  --surface-700: #374151;
  --surface-800: #1f2937;
  --surface-900: #111827;
  --surface-1000: #0f0e13;

  // Inputs
  --input-background-light: #f5f5f5;
  --input-background-dark: #364250;
  --input-border-light: #00000024;
  --input-border-dark: #6a6d7242;
  --input-text-light: #000000;
  --input-text-dark: #ffffff;
  --input-placeholder-light: #6a6d72;
  --input-placeholder-dark: #abb1b6;

  // Anchor
  --anchor-light: #7561ab;
  --anchor-dark: #a39ddc;

  // General
  --primary: #070606;
  --background--gray: #f5f5f5;
  --background: #fff;
  --success: #1ea44b;
  --success-hover: #008a5f;
  --error: #da282b;
  --warning: #e6b000;
  --disabled: var(--primary-200);
  --link: #4d7c88;
  --disabled-bg: #ebebeb;
  --disabled-text: #888888;

  // Badges
  --badge-tx-accepted: #039400;
  --badge-bg-accepted: #bfe3bd;
  --badge-tx-picking: #095077;
  --badge-bg-picking: #c5efff;
  --badge-tx-delivering: #804000;
  --badge-bg-delivering: #ffdcb0;
  --badge-tx-pending: #2340b1;
  --badge-bg-pending: #dbeafe;
  --badge-tx-finished: #5b005b;
  --badge-bg-finished: #bcbae7;
  --badge-tx-canceled: #434343;
  --badge-bg-canceled: #dbdbdb;
  --badge-bg-created: #f5f5dc;
  --badge-tx-created: #8b4513;
  --badge-tx-paying: #ffffff;
  --badge-bg-paying: #f3cd6d;

  // Toasts
  --toast-error: #efa0a0;
  --toast-error-text: #7f5555;
  --toast-error-title: #533639;
  --toast-warn: #ffe37f;
  --toast-warn-text: #7f713f;
  --toast-warn-title: #4c4426;
  --toast-ok: #92d49b;
  --toast-ok-text: #496a4d;
  --toast-ok-title: #2b3f2e;
  --toast-info: #b7dffd;
  --toast-info-text: #5b6f7e;
  --toast-info-title: #36424b;
}
