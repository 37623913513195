@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-green-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(189, 255, 180, 0.24) -2.54%,
      #ffffff 19.68%
    );
  }

  .bg-dark-green-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(189, 255, 180, 0.24) -2.54%,
      #364250 19.68%
    );
  }

  .bg-red-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(254, 226, 226, 0.24) -2.54%,
      #ffffff 19.68%
    );
  }
  .bg-dark-red-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(254, 226, 226, 0.24) -2.54%,
      #364250 19.68%
    );
  }

  .bg-blue-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(207, 248, 255, 0.24) -2.54%,
      #ffffff 19.68%
    );
  }

  .bg-dark-blue-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(207, 248, 255, 0.24) -2.54%,
      #364250 19.68%
    );
  }

  .bg-yellow-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(255, 255, 96, 0.24) -2.54%,
      #ffffff 19.68%
    );
  }

  .bg-dark-yellow-gradient {
    background: linear-gradient(
      90.41deg,
      rgba(255, 255, 96, 0.24) -2.54%,
      #364250 19.68%
    );
  }
}

@import "./styles/colors";
@import "./styles/components/button.scss";
@import "./styles/components/input.scss";
@import "./styles/components/textarea.scss";
